/**
 *  @file       operations/erroroperations.js
 *  @author     Alex Huctwith <alex@kingsds.network>
 *
 *  @date       April 2022
 *
 *              Portal client operations related to displaying errors
 */

/**
 * Display an error to the user
 * @param {string} error text to display to the user
 */
export class ErrorOperations {
  static displayError(error) {
    alert(error);
  }

  /**
   * Display a box prompting the user with a question related to the error
   * @param {string} error text to display to the user
   * @returns {boolean} true if the user answered yes, false if they answered no
   */
  static displayErrorQuestion(error) {
    return confirm(error);
  }
  
  /**
   * Called after the timeout specified in the oauth token
   * At this point they proxy keystore has expired and the user must log in again
   */
  static handleDisconnect() {
    // @todo: once oAuth refresh is available, do that; until then, at least leave
    // the user logged in until they try to do something requiring auth'n
    
    // ErrorOperations.displayError("Login timed out, please log in again.");
    // LoginOperations.handleLogout();
  }
}
