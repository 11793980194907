/**
 * @file    DonateTab.jsx - Exports a function which renders an interface for donating credits.
 *
 * @author  Severn Lortie <severn@distributive.network>, Alex Huctwith <alex@kingsds.network>
 * @author  Kirill Kirnichansky <kirill@kingsds.network>, Bryan Hoang <bryan@kingsds.network>
 * @date    April 2022, August 2022
 */
import { useContext, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Elements';
import { useForm } from '@/hooks/useForm';
import { debugging } from '@/lib/debugging';
import {
  AccountsContext,
  AccountsDispatchContext,
} from '../../../stores/AccountsContext';
import { AccountSelect } from '../../AccountSelect';
import { AccountOperations } from '../../../api/AccountOperations';
import { CreditsInput } from '../../CreditsInput';
import { ConfirmDonationModal } from '../../Modals';


const makeDonationAccount = (address, name) => { 
  return { address: new window.dcp.wallet.Address(address), label: name }
};
const donationAccounts = [
  makeDonationAccount('0xfc587308ce67b8dc68607db24e1ccdeecc1097d2', 'World Community Grid'),
  makeDonationAccount('0x39858577b39a9c6de9b4eb48eadf609079b6ec2e', 'Laurier University'),
  makeDonationAccount('0x2ae0d4fe077747e82016bc108d0b87bca5c4651c', "Queen's University")
];

/**
 * Renders a component used for transferring credits between accounts currently owned by the user.
 *
 * @param   {object}      _props Component properties.
 * @returns {JSX.Element}        The transfer form component.
 */
export function DonateTab(_props) {
  const { t } = useTranslation();
  const accounts = useContext(AccountsContext);
  const donationInformation = useRef({});
  const { handleUpdateAccounts } = useContext(AccountsDispatchContext);
  const [isThankYouModalOpen, setIsThankYouModalOpen] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    setError,
    setValue,
    formState: { errors },
  } = useForm({
    reValidateMode: 'onChange',
  });

  function closeThankYouModal()
  {
    setIsThankYouModalOpen(false);
  }
  function openThankYouModal()
  {
    setIsThankYouModalOpen(true);
  }

  async function donateCredits({ fromAccount, toAccount, amount })
  {
    try
    {
      const donationAccount = donationAccounts.find(a => a.address.eq(toAccount));
      const refreshedAccounts = await AccountOperations.transferCredits(
        accounts.find(a => a.dbKey === Number(fromAccount)),
        donationAccount,
        accounts,
        amount,
      );
      donationInformation.current = { amount, toAccountLabel: donationAccount.label }
      if (refreshedAccounts)
      {
        openThankYouModal()
        handleUpdateAccounts(refreshedAccounts);
      }
      reset();
    }
    catch (error)
    {
      debugging('transfer') && console.error(error.message);
      setError('amount', { type: 'server', message: error.message });
    }
  }

  function handleFromAccountChange()
  {
    const fromInput = document.getElementById('fromAccount');
    const toInput = document.getElementById('toAccount');

    // enabled previously disabled option, disable newly selected from-account
    // options[0] is placeholder
    for (let i = 1; i < toInput.options.length; i++)
    {
      if (toInput.options[i].disabled) toInput.options[i].disabled = false;
      if (toInput.options[i].value === fromInput.value)
      {
        toInput.options[i].disabled = true;

        // Update the `toAccount` if one has already been selected to stop `fromAccount` and
        // `toAccount` from being the same.
        if (toInput.value !== '')
        {
          const availableToAccounts = Array.from(toInput.options).filter((option) => !option.disabled);
          setValue('toAccount', availableToAccounts[0].value);
        }
      }
    }

    // Update amount to be empty
    setValue('amount', null);
  }

  return (
    <>
      <form
        id="donate-form"
        onSubmit={handleSubmit(donateCredits)}
      >
        <AccountSelect
          label={t('from')}
          placeholder={t('select_account')}
          accounts={accounts}
          error={errors.fromAccount}
          {...register('fromAccount', {
            required: t('must_specify_account_to_transfer_from'),
            onChange: handleFromAccountChange,
          })}
        />
        { errors.fromAccount && <p>{errors.fromAccount?.message}</p> }
        <AccountSelect
          label={t('to')}
          placeholder={t('select_account')}
          accounts={donationAccounts}
          error={errors.toAccount}
          {...register('toAccount', { required: t('must_specify_account_to_transfer_to') })}
        />
        { errors.toAccount && <p>{errors.toAccount?.message}</p> }
        <CreditsInput
          label={t('amount')}
          className={`${errors.amount ? 'invalid' : ''}`}
          {...register('amount', {
            required: t('amount_to_transfer_is_required'),
          })}
        />
        { errors.amount && <p>{errors.amount?.message}</p> }
        <Button colour text={t('donate')} form="donate-form" />
      </form>
      <ConfirmDonationModal
        isOpen={isThankYouModalOpen}
        closeModal={closeThankYouModal}
        toAccountLabel={donationInformation.current.toAccountLabel}
        amount={donationInformation.current.amount}
      />
    </>
  );
}
