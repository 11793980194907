/**
 * @file    ChangeAccountPasswordModal.jsx
 *
 *          Exports a function that renders a modal component to change an account's password.
 *
 * @author  Bryan Hoang <bryan@kingsds.network>
 * @date    July 2022
 */
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { PasswordInput } from '@/components/Form';
import { Modal } from '@/components/Elements';
import { useForm } from '@/hooks/useForm';
import { AccountsDispatchContext } from '../../stores/AccountsContext';
import { AccountOperations } from '../../api/AccountOperations';

const formId = 'newPasswordForm';

/**
 * Renders a modal to change an account's password.
 *
 * @param   {object}      props            Component properties.
 * @param   {boolean}     props.isOpen     If the modal is open.
 * @param   {function}    props.closeModal Callback to close the modal.
 * @param   {object}      props.account    The account to change the password for.
 * @returns {JSX.Element}                  The rendered component.
 */
export function ChangeAccountPasswordModal({ isOpen, closeModal, account })
{
  const { t } = useTranslation();
  const { handleUpdateAccounts } = useContext(AccountsDispatchContext);
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  async function savePassword({ oldPassword, newPassword })
  {
    const updatedAccount = await AccountOperations.changeAccountPassword(
      account,
      oldPassword,
      newPassword,
    );
    resetAndCloseModal();
    handleUpdateAccounts([updatedAccount]);
  }

  async function validateOldPassword(password)
  {
    try
    {
      await (await new window.dcp.wallet.Keystore(account)).unlock(password);
    }
    catch (error)
    {
      return false;
    }

    return true;
  }

  function validatePasswordsMatch()
  {
    if (watch('newPassword', '') !== watch('confirmPassword', ''))
    {
      return false;
    }

    return true;
  }

  function resetAndCloseModal()
  {
    reset();
    closeModal();
  }

  return (
    <Modal
      isOpen={isOpen}
      closeModal={resetAndCloseModal}
      heading={t('new_account_password')}
      isInvalid={Object.keys(errors).length > 0}
      onSubmit={handleSubmit(savePassword)}
      formId={formId}
      defaultLabel={t('save')}
      loadingLabel={t('saving')}
    >
      <PasswordInput
        label={t('old_password')}
        autoFocus
        {...register('oldPassword', { validate: validateOldPassword })}
      />
      {errors.oldPassword && (
        <p className="errorMessage">{t('old_password_incorrect')}</p>
      )}
      <PasswordInput
        label={t('new_password')}
        autoComplete="new-password"
        {...register('newPassword', { validate: validatePasswordsMatch })}
      />
      <PasswordInput
        label={t('confirm_password')}
        autoComplete="new-password"
        {...register('confirmPassword', { validate: validatePasswordsMatch })}
      />
      {(errors.newPassword || errors.confirmPassword) && (
        <p className="errorMessage">{t('password_must_match')}</p>
      )}
    </Modal>
  );
}
