/**
 * @file       Transactions.jsx
 * @author     Kevin Yu <kevin@distributive.network>
 *
 * @date       May 2024
 *
 *             A component which displays transactions in a tabular format.
 */

import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './Transactions.css';
import { HistoryContext } from '../../stores/HistoryContext';
import { i18n } from '@/lib/i18n';
import { Balance } from '../Balance';
import BigNumber from 'bignumber.js';
import { formatBankAccountAddress } from '../../utils/format';


const dateTimeFormat = new Intl.DateTimeFormat(i18n.language, {
  month: 'short',
  day: '2-digit',
  year: 'numeric',
  hour: '2-digit',
  hour12: false,
  minute: '2-digit',
})

/**
 * @param {string} date the string date format  
 * @returns {string} a formatted date in the user's respective locale
 */
function formatDate(date) 
{
  return dateTimeFormat.format(new Date(date));
}

/**
 * Sums up the total of a column depending on the specified transaction_type
 * 
 * @param {Array<object>} transactions list of deposit transactions
 * @returns {Number} the sum of the total withdraw transactions
 */
function totalAmount(transactions, transaction_type) 
{
  return transactions.filter(record => record.transactionType === transaction_type) 
    .reduce((sum, record) => sum.plus(record.amount_credits), new BigNumber(0));
}

/**
 * Renders a list of transactions in a table.
 *
 * @param   {object}      props Component properties.
 * @returns {JSX.Element} transactions table.
 */
export function Transactions(_props) {
  const { filteredTransactions:transactions } = useContext(HistoryContext);
  const { t } = useTranslation();
  const [sortConfig, setSortConfig] = useState({});

  /**
   * Determine the sorting direction for the transaction table
   * 
   * @param {*} key the object key name
   */
  function requestSort(key)
  {
    let direction = 'descending';
    if (sortConfig.key === key && sortConfig.direction === 'descending')
      direction = 'ascending';
    setSortConfig({ key, direction });
  }

  /**
   * Renders the appropriate arrow direction icon
   * 
   * @param {*} name the object key name
   * @returns {string} ascending or descending to update the classname
   */
  function getClassName(name)
  {
    return sortConfig.key === name ? sortConfig.direction : 'ascending';
  }

  transactions.sort((a, b) => {
    if (new Date(a[sortConfig.key]) < new Date(b[sortConfig.key]))
      return sortConfig.direction === 'ascending' ? -1 : 1;
    if (new Date(a[sortConfig.key]) > new Date(b[sortConfig.key]))
      return sortConfig.direction === 'ascending' ? 1 : -1;
    return 0;
  });


  return (
    <div className="transactions-table-container">
      <table className="transactions-table font-monospace">
        <colgroup>
          <col style={{ width: '15%' }}/>
          <col style={{ width: '15%' }}/>
          <col style={{ width: '11%' }}/>
          <col style={{ width: '15%' }}/>
          <col style={{ width: '15%' }}/>
        </colgroup>
        {/* Transaction Table Headers */}
        <thead>
          <tr>
            <th>
              <button
                type="button"
                onClick={() => requestSort('created_at')}
                className={getClassName('created_at')}
              >
                {t('transaction_date')}
              </button>
            </th>
            <th>{t('transaction_type')}</th>
            <th>{t('details')}</th>
            <th>{t('credits_in')}</th>
            <th>{t('credits_out')}</th>
          </tr>
        </thead>
        {/* Transaction Table Content  */}
        <tbody>
          {transactions.length > 0 ? transactions.map((record) =>
            ( 
              <tr key={record.created_at}>
                <td>{formatDate(record.created_at)}</td>
                <td>{record.transactionType === 'deposit' ? t('buy') : t('sell')}</td>
                <td>
                  {record.transactionType === 'deposit'
                    ? formatBankAccountAddress(record.bank_account)
                    : record.ppemail || t('no_email', { ns: 'accounts' })}
                </td>
                <td>
                  <div className="transaction-table-credits">
                    {record.transactionType === 'deposit' ? (
                      <Balance balance={new BigNumber(record.amount_credits)}/>
                    ) : '' }
                  </div>
                </td>
                <td>
                  <div className="transaction-table-credits">
                    {record.transactionType === 'withdraw' ? (
                      <Balance balance={new BigNumber(record.amount)}/>
                    ) : '' }
                  </div>
                </td>
              </tr>
            )
          ) : (
            <tr>
              <td colSpan="5" className="transactions-table-no-data">
                {t('no_data_available')}
              </td>
            </tr>
          )}
        </tbody>

        {/* Transaction Table Footer*/}
        <tfoot>
          <tr>
            <td>{t('totals')}</td>
            <td></td>
            <td></td>
            <td>
              <div className="transactions-total">
                <Balance balance={totalAmount(transactions, 'deposit')}/>
              </div>
            </td>
            <td>
              <div className="transactions-total">
                <Balance balance={totalAmount(transactions, 'widthdraw')}/>
              </div>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
}
