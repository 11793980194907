/** 
 * @file       HistoryHeader.jsx
 * @author     Kevin Yu <kevin@distributive.network>
 * 
 * @date       May 2024
 *
 *             The header of the history page
 */

import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { HistoryContext } from '../../stores/HistoryContext';
import './HistoryHeader.css';
import BigNumber from 'bignumber.js';
import { Balance } from '../Balance';
import { formatBankAccountAddress } from '../../utils/format';
import { BreadCrumb } from '@/components/Elements/BreadCrumb/BreadCrumb';


/**
 * The header of the transactions page
 * @param {*} _props component props
 * @returns bank id and current balance
 */
export function HistoryHeader(_props) {
  const { t } = useTranslation();

  const { selectedBankAccount } = useContext(HistoryContext);

  if (!selectedBankAccount)
    return <></>;

  return (
    <>
      <BreadCrumb routes={[
        { label: 'Your Accounts', href: '/accounts' }, 
        { label: 'Transaction History', href: '/accounts/history' }
      ]}/>
      <div className="history-header">
        <h1 className="history-header-title">
          <span>
            {selectedBankAccount?.label}
          </span>
          <span>
            {formatBankAccountAddress(selectedBankAccount?.address)}
          </span>
        </h1>
        <div className="history-header-balance">
          <label>{t('balance')}</label>
          <output>
            <Balance balance={new BigNumber(selectedBankAccount?.balance)}/>
          </output>
        </div>
      </div>
      <div className="history-header-line"/>
    </>
  );
}
