/**
 * @file    HistoryContext.jsx
 *          Contains state management for account transactions.
 *
 *
 * @author  Kevin Yu <kevin@distributive.network>
 * @date    May 2024
 */

import { createContext, useCallback, useState, } from 'react';
import { fetchTransactions } from '../api/fetchTransactions';

export const HistoryContext = createContext();

/**
 * Provides context to its children for accessing the `history` state and
 * dispatching events to update it.
 * 
 * @param   {object}          props          Component props.
 * @param   {React.ReactNode} props.children Children of the component.
 * @returns {JSX.Element}                    The provider component.
 */
export const HistoryProvider = ({ children }) => {
  const [transactions, setTransactions] = useState([]);
  const [filteredTransactions, setFilteredTransactions] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [transactionType, setTransactionType] = useState('');
  const [selectedBankAccount, setSelectedBankAccount] = useState(null);
  const [activeSelectedBankAccount, setActiveSelectedBankAccount] = useState(null);
    
  /**
   * Filters the list of transactions based on the selected filters
   */
  const filterTransactions = useCallback(() => {
    let filteredTxs = [];

    // Get transactions matching this keystore id
    if (selectedBankAccount)
    {
      filteredTxs = transactions.filter((record) => {
        return selectedBankAccount?.address.eq(record.bank_account);
      });
    }

    // Filter Transaction Type
    if (transactionType === 'Buy')
    {
      filteredTxs = filteredTxs.filter((record) => record.transactionType === 'deposit'); 
    }
    else if (transactionType === 'Sell')
    {
      filteredTxs = filteredTxs.filter((record) => record.transactionType === 'withdraw'); 
    }

    // Filter start dates
    if (startDate)
    {
      const startDateObj = new Date(Date.parse(startDate + 'T00:00:00'));
      filteredTxs = filteredTxs.filter((record) => new Date(record.created_at) >= startDateObj);
    }

    // Filter end dates
    if (endDate)
    {
      const endDateObj = new Date(Date.parse(endDate + 'T00:00:00'));
      filteredTxs = filteredTxs.filter((record) => !(new Date(record.created_at) > endDateObj));
    }
    
    // Update active selected account and list of transactions
    setFilteredTransactions(filteredTxs);
  }, [endDate, selectedBankAccount, startDate, transactionType, transactions]);


  /**
   * Fetch the list of transactions for all bank accounts
   */
  const getTransactions = useCallback(async () => {
    setTransactions(await fetchTransactions());
  }, []);

  return (
    <HistoryContext.Provider value={{ 
      transactions,
      setTransactions,
      filteredTransactions,
      setFilteredTransactions,
      startDate,
      setStartDate,
      endDate, 
      setEndDate,
      transactionType,
      setTransactionType,
      selectedBankAccount,
      setSelectedBankAccount,
      activeSelectedBankAccount, 
      setActiveSelectedBankAccount,
      filterTransactions,
      getTransactions,
    }}>
      {children}
    </HistoryContext.Provider>
  );
};
