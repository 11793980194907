/**
 *  @file       useDCPWorker.js
 *  @author     Kirill Kirnichansky <kirill@distributive.network>
 *  @date       April 2023
 *
 *  We need a dcp worker component in both WorkerPanel and SidebarWorker, so instead of
 *  having the originManager setting logic and toggleWorker in two places, this wrapper hook handles it one place.
 */

import { useEffect } from 'react';
import { useDCPWorker as useDCPWorkerHook } from 'use-dcp-worker';
/**
 *  This hook wraps the use-dcp-worker hook and is responsible for setting the 
 *  originManager in the window and adding toggleWorker to the library returned.
 */
export function useDCPWorker(options)
{
  const lib = useDCPWorkerHook(options);

  lib.toggleWorker = () => {
    if (!lib.worker)
    {
      console.error('Worker is not loaded. use-dcp-worker failed?');
    }
    else if (lib.workerState.working)
    {
      lib.worker.stop().catch((error) => 
        console.error('Stopping the worker threw an unexpected error:', error)
      );
    }
    else
    {
      lib.worker.start().catch((error) => 
        console.error('Starting the worker threw an unexpected error:', error)
      );
    }
  }
  
  // useEffect since this is a side effect of rendering + perf
  useEffect(() => {
    if (lib.worker)
      window.dcpWorker = lib.worker;
  }, [lib.worker]);
  
  // show how many workers are working in the title
  useEffect(() => {
    document.title = `DCP Portal | ${lib.workerState.workingSandboxes} working`;
  }, [lib.workerState.workingSandboxes]);

  return lib;
}
