/**
 * @file       AccountOperations.js
 * @author     Alex Huctwith <alex@kingsds.network>
 * @author     Bryan Hoang <bryan@kingsds.network>
 *
 * @date       April 2022, June 2022
 *
 *             Portal client operations related to bank accounts
 */
import BigNumber from 'bignumber.js';

import { ErrorOperations } from '@/operations/ErrorOperations';
import { LoginOperations } from '@/features/auth';
import { debugging } from '@/lib/debugging';

export class AccountOperations
{
  /**
   * Creates a new account and adds it to the portal. Makes a request to the
   * `saveKey` operation on the portal.
   *
   * @param  {string}            label    The label to assign to the new keystore.
   * @param  {password}          password The password to assign to the new keystore.
   * @return {Promise<Keystore>}          The created keystore.
   */
  static async createAccount(label = 'unnamed', password = '')
  {
    if (!window.portalConnection)
    {
      await LoginOperations.load();
    }

    {
      const keystore = await new window.wallet.Keystore(null, password);
      await keystore.unlock(password, 200, false);
      keystore.label = label;
      
      /**
       * The portal authorizes `keystore` so that we can verify that the client making the request 
       * can access the keystore and therefore, view its balance (This is a pre-auth for viewKeystores).
       */
      const saveKeyMsg = new window.portalConnection.Request({
        operation: 'saveKey',
        data: { keystore },
      });
      await saveKeyMsg.authorize(keystore);

      const response = await saveKeyMsg.send();
      debugging('account') && console.debug('043: saveKey response:', response);

      if (!response.success)
      {
        return ErrorOperations.displayError(response.payload?.message);
      }
      else
      {
        return this.#createAccountFromPayload(response.payload);
      }
    }
  }

  /**
   * Upload a keystore to the portal server.
   *
   * @param   {object}          keystore The unlocked keystore to upload.
   * @param   {string}          password The password of the keystore.
   * @returns {Promise<object>}          The uploaded keystore.
   */
  static async uploadAccount(keystore)
  {
    if (!window.portalConnection)
    {
      await LoginOperations.load();
    }

    debugging('account') && console.debug('Uploading account', keystore);
    /**
     * The authorization reasoning for this request is described before the same request made in 
     * AccountOperations.createAccount(label, password).
     */
    const saveKeyMsg = new window.portalConnection.Request({
      operation: 'saveKey',
      data: { keystore },
    });
    await saveKeyMsg.authorize(keystore);

    const response = await saveKeyMsg.send();
    debugging('account') && console.debug('076: saveKey response', response);

    if (!response.success)
    {
      return ErrorOperations.displayError(response.payload?.message);
    }
    else
    {
      return this.#createAccountFromPayload(response.payload);
    }
  }

  /**
   * Creates an account object from the payload returned by the server.
   *
   * Makes sure anything returned by the server is in the correct format. e.g., has a `dbKey`
   * property.
   *
   * @param   {object} payload      The payload returned by the server.
   * @param   {object} payload.pack An package containing the keystore and the dbKey.
   * @returns {object}              The account object.
   */
  static async #createAccountFromPayload({ pack })
  {
    // Set up the returned account to have the same properties as an updated account.
    const [createdAccountObject, dbKey] = pack;
    const account = await new window.wallet.Keystore(createdAccountObject);

    debugging('account') && console.debug('AccountOperations: createdAccountObject -', createdAccountObject);

    account.label = createdAccountObject.label;
    account.balance = new BigNumber(createdAccountObject.balance);

    /**
     * Setting the dbKey incase the user tries to delete the keystore. Otherwise immediately trying
     * to delete it will fail.
     */
    account.dbKey = dbKey;

    debugging('account') && console.debug('AccountOperations:', account);

    return account;
  }

  /**
   * Update an account in the portal.
   *
   * Makes a request to the `updateKey` operation on the portal.
   *
   * @param   {object}          keystore The account to update.
   * @returns {Promise<object>}          The updated account.
   */
  static async updateAccount(keystore)
  {
    if (!window.portalConnection)
    {
      await LoginOperations.load();
    }
    else
    {
      // Unlock the keystore to use the private key for signing the message.
      await keystore.unlock(null, 200, false);
      const dbKey = keystore.dbKey;
      const request = new window.portalConnection.Request({ 
        operation: 'updateKey', 
        data: { keystore, dbKey },
      });
      const response = await request.send();
      debugging('account') && console.debug('135: updateKey response:', response);
      if (!response.success) {
        ErrorOperations.displayError(response.payload?.message);
      } else {
        /**
         * Since we already need to unlock the account to update it, we might as well update the
         * balance before we display it to the user.
         *
         * refreshBalance returns an Array - we only want the updated account, so return index 0.
         */
        return (await this.refreshBalance([keystore]))[0];
      }
    }
  }

  /**
   * Change the password of an account.
   *
   * Uses `this.updateAccount` to send the request to the server.
   *
   * @param   {object}          keystore    The account to change the password of.
   * @param   {string}          oldPassword The old password of the account.
   * @param   {string}          newPassword The new password of the account.
   * @returns {Promise<object>}             The account with the new password.
   */
  static async changeAccountPassword(keystore, oldPassword, newPassword)
  {
    /**
     * Lock the keystore so that we can attempt to use the oldPassword to unlock it *before* we try
     * changing the password to newPassword.
     *
     * This is because the keystore is often unlocked to begin with and so without this line, we could
     * enter *any* oldPassword and keystore.unlock() would not lock us out from an already-unlocked
     * keystore and the newPassword would be assigned, REGARDLESS. We want to make sure that the
     * oldPassword given to us by the user is the correct one - this is why we try using it to unlock
     * the keystore from scratch.
     */
    keystore.lock();
    // Unlock the keystore to use the private key for changing the password.
    await keystore.unlock(oldPassword, 200, false);
    const pk = await keystore.getPrivateKey();
    const changed = await new window.wallet.Keystore(pk, newPassword);
    /**
     * Copy over other business logic related values from the old keystore that
     * the constructor doesn't copy over.
     */
    changed.label = keystore.label;
    changed.balance = keystore.balance;
    changed.dbKey = keystore.dbKey;
    
    /**
     * Newly-constructed keystores are automatically locked so unlock here before updating account
     * to prevent the unlock modal being opened because wallet.passphrasePrompt was called
     */
    await changed.unlock(newPassword, 200, false);
    return await this.updateAccount(changed);
  }

  /**
   * Delete an account from the portal.
   *
   * Makes a request to the `removeKey` operation on the portal.
   *
   * @param   {object}          keystore The account to delete.
   * @returns {Promise<object>}          The address of the deleted account.
   */
  static async deleteAccount(keystore)
  {
    if (!window.portalConnection)
    {
      await LoginOperations.load();
    }
    else
    {
      const request = new window.portalConnection.Request({
        operation: 'removeKey', 
        data: { keystore, dbKey: keystore.dbKey },
      });
      const response = await request.send();
      debugging('account') && console.debug('212: removeKey response:', response);

      if(!response.success)
      {
        ErrorOperations.displayError(response.payload?.message);
      }
      else
      {
        return keystore.dbKey;
      }
    }
  }

  /**
   * Fetch the accounts from the server.
   *
   * Makes a request to the `viewKeystores` operation on the portal.
   *
   * @returns {Promise<object[]>} The keystores fetched from the server.
   */
  static async refreshKeystores()
  {
    if (
      !window.protocol ||
      !window.portalConnection
    )
    {
      await LoginOperations.load();
    }

    const response = await window.portalConnection.request('viewKeystores', {});
    debugging('account') && console.debug('239: viewKeystores response', response);
    const processedKeystores = [];

    if (!response.success)
      ErrorOperations.displayError(response.payload?.message);
    else
    {
      for (const keystoreInfo of response.payload)
      {
        const ks = await new window.wallet.Keystore(keystoreInfo);
        // Some keystores stored in the database don't have a label.
        ks.label ??= '';
        ks.dbKey = keystoreInfo.dbKey;
        ks.balance = new BigNumber(keystoreInfo.balance);

        // unlock keystores without password
        try
        {
          await ks.unlock('', 200, true);
        }
        catch (e) { }

        processedKeystores.push(ks);
      }
    }

    return processedKeystores;
  }

  /**
   * Refresh balances of a list of accounts.
   *
   * Makes a request to the `getAccounts` operation on the portal, which fetches account information from the bank-teller
   *
   * @param   {array}             keystores The accounts to refresh the balance of.
   * @returns {Promise<object[]>}           The accounts with the refreshed balances.
   */
  static async refreshBalance(keystores)
  {
    if (!window.portalConnection)
    {
      await LoginOperations.load();
    }
    else
    {
      const addresses = keystores.filter((ks) => Boolean(ks)).map((keystore) => keystore.address);
      const response = await window.portalConnection.request(
      'getAccounts',
      { addresses },
      );
      debugging('account') && console.debug('288: getAccounts response:', response);

      if (!response.success)
        ErrorOperations.displayError(response.payload.message);
      else
      {
        const refreshed = keystores.map((keystore) => {
          const account = response.payload.find((account) => keystore.address.eq(account.address));
          if (account)
            keystore.balance = new BigNumber(account.balance);
          return keystore;
        });

        return refreshed;
      }
    }
  }

  /**
   * Transfer credits between accounts.
   *
   * Makes a request to the `transferCredits` operation on the portal.
   *
   * @param   {object}            fromKeystore The account to transfer from.
   * @param   {object}            toKeystore   The account to transfer to.
   * @param   {object[]}          accounts     A list of the user's accounts (used for refreshing).
   * @param   {number}            amount       The amount to transfer.
   * @returns {Promise<object[]>}              Accounts with refreshed balances.
   */
  static async transferCredits(fromKeystore, toKeystore, accounts, amount)
  {
    if (!window.bankTellerConnection)
    {
      await LoginOperations.load();
    }
    else
    {
      // Unlock the keystore to use the private key for signing the message.
      await fromKeystore.unlock(null, 200, true);
      
      /**
       * The keystore that is being debited must be authorized by the request message to ensure that it is allowed to 
       * make that transaction.
       */
      const transferCreditsMsg = new window.bankTellerConnection.Request({
        operation: 'transferCredits',
        data: {
          fromAccount: fromKeystore.address,
          toAccount: toKeystore.address,
          amount,
        },
      });
      await transferCreditsMsg.authorize(fromKeystore);

      const response = await transferCreditsMsg.send();
      debugging('account') && console.debug(`328: transferCredits response:`, response);

      if (!response.success)
      {
        const message = response.payload.message.split('ENOTFOUND:');
        if (message.length > 1)
          return ErrorOperations.displayError(message[1].trim());

        return ErrorOperations.displayError(message[0]);
      }

      return await this.refreshBalance(accounts);
    }
  }
}
