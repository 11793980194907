/**
 * @file    providers/app.jsx - Exports a react component that wraps its
 *          children with context providers, for the contexts to be accessible
 *          by the children. e.g., providing the same instance of a worker to
 *          the web app.
 *
 * @author  Bryan Hoang <bryan@distributive.network>
 * @date    Aug. 2023
 */

import { BrowserRouter } from 'react-router-dom';
import { WorkerProvider } from 'use-dcp-worker';
import { ErrorBoundary } from 'react-error-boundary';

import { AccountsProvider } from '@/features/accounts';
import { UserProvider } from '@/features/user';
import { HistoryProvider } from '@/features/accounts/stores/HistoryContext';
import { Toaster } from '@/components/Elements/Toast/Toaster';
import { MainErrorFallback } from '@/components/errors/main.jsx';

/**
 * Renders children passed to it with context providers wrapped around the
 * children.
 *
 * @param  {object}          props    Component properties.
 * @param  {React.ReactNode} children The children to wrap.
 * @return {JSX.Element}              The wrapped children.
 */
export function AppProvider({ children })
{
  return (
    <ErrorBoundary FallbackComponent={MainErrorFallback}>
      <BrowserRouter>
        <AccountsProvider>
          <HistoryProvider>
            <UserProvider>
              <WorkerProvider>
                {children}
              </WorkerProvider>
            </UserProvider>
          </HistoryProvider>
        </AccountsProvider>
      </BrowserRouter>
    </ErrorBoundary>
  );
}
