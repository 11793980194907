/** @file       AccountDropdown.jsx
 *  @author     Alex Huctwith <alex@kingsds.network>
 *              Arnab Sagar   <arnab@kingsds.network>
 *              Kevin Yu   <kevin@distributive.network>
 *
 *  @date       April 2022
 *
 *              A component which displays the "more" pane for an account in the accounts list
 *              defaults to hidden and is shown by more carat button on account widget
 *              Props:
 *                - account: the keystore object for the account
 */
import { useTranslation } from 'react-i18next';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { AccountsDispatchContext } from '../../../../stores/AccountsContext';
import { AccountOperations } from '../../../../api/AccountOperations';
import { NavLink } from 'react-router-dom';

import './AccountDropdown.css';

import { useContext } from 'react';
const { VITE_ASSETS_PATH } = import.meta.env;

/**
 * Renders a component which displays the "more" pane for an account in the accounts list.
 *
 * @param   {object}      props                                Component properties.
 * @param   {object}      props.account                        The account to display options for.
 * @param   {function}    props.onOpenChanged                  Event handler called when the open state
 *                                                             of the dropdown menu changes.
 * @param   {function}    props.openRenameModal                Opens the modal to rename an account.
 * @param   {function}    props.openChangeAccountPasswordModal Opens the modal to change the password
 *                                                             for an account.
 * @param   {function}    props.openDeleteModal                Opens the modal to delete an account.
 * @param   {function}    props.openDownloadModal              Opens the modal to download an account.
 * @param   {function}    props.handleNavigateToTransactions   Navigates to transactions page to show account history.
 * @returns {JSX.Element}                                      The component.
 */
export function AccountDropdown({
  account,
  onOpenChanged,
  openRenameModal,
  openChangeAccountPasswordModal,
  openDeleteModal,
  openDownloadModal,
  isUnlocked,
  handleNavigateToTransactions
}) {
  const { t } = useTranslation();

  const { handleUpdateAccounts } = useContext(AccountsDispatchContext);

  async function unlockPressed()
  {
    await account.unlock(null, 200, true);
    const updatedAccount = await AccountOperations.refreshBalance([account]);
    if (updatedAccount)
      handleUpdateAccounts(updatedAccount);
  }

  async function lockPressed()
  {
    await account.lock();
    const updatedAccount = await AccountOperations.refreshBalance([account]);
    if (updatedAccount)
      handleUpdateAccounts(updatedAccount);
  }

  function copyKeystoreJSON()
  {
    // TODO(willpringle): remove when node-oauth lands, temporary keystore clipboard copy
    const objKeystore = account.toJSON();
    const leanKeystore = {
      address: objKeystore.address,
      crypto:  objKeystore.crypto,
      version: objKeystore.version,
    };
    return navigator.clipboard.writeText(JSON.stringify(leanKeystore));
  }
  return (
    <DropdownMenu.Root className="accountMore" onOpenChange={onOpenChanged}>
      <DropdownMenu.Trigger className="optionsButton">
        {isUnlocked ? (
          <svg className="dropdown-menu-icon" aria-label="Dropdown Menu" role="img">
            <use xlinkHref={`${VITE_ASSETS_PATH}/icons/dropdown-menu.svg#dropdown-menu`}></use>
          </svg>
        ) : (
          <svg className="locked-icon" aria-label="Locked Account Icon" role="img">
            <use xlinkHref={`${VITE_ASSETS_PATH}/icons/locked-icon.svg#locked-icon`}></use>
          </svg>
        )}
      </DropdownMenu.Trigger>
      <DropdownMenu.Content
        className="accountMoreOptions"
        align="end"
        alignOffset={0}
        // Allow modals opened from this component to control the focus.
        onCloseAutoFocus={(e) => e.preventDefault()}
      >
        <DropdownMenu.Item onSelect={copyKeystoreJSON}>
          {t('copy')}
        </DropdownMenu.Item>
        <DropdownMenu.Item id="accountDeleteAction" onSelect={openDeleteModal}>
          {t('delete')}
        </DropdownMenu.Item>
        {isUnlocked ? (
          <DropdownMenu.Item onSelect={lockPressed}>
            {t('lock')}
          </DropdownMenu.Item>
        ) : (
          <DropdownMenu.Item onSelect={unlockPressed}>
            {t('unlock')}
          </DropdownMenu.Item>
        )}
        {isUnlocked && (
          <>
            <DropdownMenu.Item onSelect={openRenameModal}>
              {t('rename')}
            </DropdownMenu.Item>
            <DropdownMenu.Item onSelect={openChangeAccountPasswordModal}>
              {t('new_password')}
            </DropdownMenu.Item>
            <DropdownMenu.Item onSelect={openDownloadModal}>
              {t('download')}
            </DropdownMenu.Item>
            <DropdownMenu.Item onSelect={handleNavigateToTransactions}>
              {t('Transactions')}
            </DropdownMenu.Item>
          </>
        )}
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
}
