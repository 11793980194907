/** @file       sidenav.jsx
 *  @author     Alex Huctwith <alex@kingsds.network>
 *
 *  @date       April 2022
 *
 *              Side navbar component included from layout.js for navigating between pages
 */

import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next'

import { UserOperations, useUserPref, useUserPrefUpdate } from '@/features/user';
import { AboutModal, PatchNotesModal } from '@/features/misc';
import { LoginOperations } from '@/features/auth';

import './SideNav.css';

const { VITE_ASSETS_PATH } = import.meta.env;

const isLinkActive = ({ isActive }) => isActive ? 'active' : '';
const isAccountsLinkActive = ({ isActive }) =>
  isActive || window.location.pathname === '/' ? 'active' : '';

/**
 * Renders the side navbar.
 *
 * @param   {object}      props         The component's properties.
 * @param   {boolean}     props.isOpen  Open or close the component.
 * @param   {function}    props.onClose Event handler called when the component closes.
 * @returns {JSX.Element}               The rendered component.
 */
export const SideNav = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const [isAboutModalOpen, setIsAboutModalOpen] = useState(false);
  const [isPatchNotesModalOpen, setIsPatchNotesModalOpen] = useState(false);
  const userPreferences = useUserPref();
  const setUserPreferences = useUserPrefUpdate();

  // Determine whether we should show the user the login modal
  useEffect(() => {
    function showLoginModal()
    {
      // If we are the root of the site and the user does not have the set sessionStorage variable
      if (
        window.location.pathname === '/' &&
        !sessionStorage.hasShownAboutModalOnLogin
      )
      {
        const showAboutModalOnLogin = userPreferences.showAboutModalOnLogin;
        // Fetch user preferences from backend
        if (showAboutModalOnLogin) {
          setIsAboutModalOpen(true);

          // Add to session storage so the user wont get shown the modal again during this session
          // due to this component being re-mounted.
          sessionStorage.hasShownAboutModalOnLogin = true;
        }
      }
    }

    showLoginModal();
  }, [userPreferences]);

  async function onAboutModalSubmit(doNotShowAboutModalOnLogin)
  {
    setUserPreferences({ showAboutModalOnLogin: !doNotShowAboutModalOnLogin });
    // if the input is checked, dontShowModal will equal true
    // that means we should not show the about modal, the modal should not be
    // shown on page load
    await UserOperations.updateUserPreferences({ showAboutModalOnLogin: !doNotShowAboutModalOnLogin });
  }

  return (
    <>
      <nav id="sidebar" className={isOpen ? 'opened' : ''}>
        {/* Grouping in div for flex box positioning. */}
        <div className="sidebar-links">
          <button type="button" className="close-menu" onClick={()=>onClose()}>
            <img src={`${VITE_ASSETS_PATH}/icons/close.svg`} alt={t('close_menu')} />
          </button>
          <NavLink to="/">
            <img src={`${VITE_ASSETS_PATH}/img/logo-banner.png`} alt="DCP" className="logo" />
          </NavLink>
          <ul className="bottomBorder">
            <li id="accountsLink">
              <NavLink className={isAccountsLinkActive} to="/accounts">
                <svg>
                  <use xlinkHref={`${VITE_ASSETS_PATH}/icons/accounts.svg#accounts`}></use>
                </svg>
                <span>{t('accounts')}</span>
              </NavLink>
            </li>
            <li>
              <NavLink className={isLinkActive} to="/worker">
                <svg>
                  <use xlinkHref={`${VITE_ASSETS_PATH}/icons/worker.svg#worker`}></use>
                </svg>
                <span>{t('worker')}</span>
              </NavLink>
            </li>
            <li>
              <NavLink className={isLinkActive} to="/user">
                <svg>
                  <use xlinkHref={`${VITE_ASSETS_PATH}/icons/user-settings.svg#user`}></use>
                </svg>
                <span>{t('user_settings')}</span>
              </NavLink>
            </li>
            <li>
              <button onClick={() => setIsAboutModalOpen(true)}>
                <svg>
                  <use xlinkHref={`${VITE_ASSETS_PATH}/icons/about.svg#about`}></use>
                </svg>
                <span>{t('about')}</span>
              </button>
            </li>
            <li id="patchNotesLink">
              <button onClick={() => setIsPatchNotesModalOpen(true)}>
                <svg>
                  <use xlinkHref={`${VITE_ASSETS_PATH}/icons/patch-notes.svg#patch`}></use>
                </svg>
                <span>{t('patch_notes')}</span>
              </button>
            </li>
            <li>
              <button onClick={() => LoginOperations.handleLogout()}>
                <svg>
                  <use xlinkHref={`${VITE_ASSETS_PATH}/icons/log-out.svg#logout`} />
                </svg>
                <span>{t('log_out')}</span>
              </button>
            </li>
          </ul>
        </div>
        <PoweredBy />
      </nav>
      <AboutModal
        isOpen={isAboutModalOpen}
        closeModal={() => setIsAboutModalOpen(false)}
        onSubmit={onAboutModalSubmit}
        // Invert logic for modal to be internally self consistent.
        doNotShowAboutModalOnLogin={!userPreferences.showAboutModalOnLogin}
      />
      <PatchNotesModal
        isOpen={isPatchNotesModalOpen}
        closeModal={() => setIsPatchNotesModalOpen(false)}
      />
    </>
  );
};

function PoweredBy()
{
  const { t } = useTranslation();
  return (
    <div className="powered-by-dcp">
      <span>{t('powered_by')}</span>
      <img src={`${import.meta.env.VITE_ASSETS_PATH}/img/c-shield.png`} alt="DCP" />
    </div>
  );
}
