/** @file       layout.jsx
 *  @author     Alex Huctwith <alex@kingsds.network>
 *
 *  @date       April 2022
 *
 *              A component which defines the base layout of a portal page.
 *              All pages use this component as a root
 *              Props:
 *                - classname: an optional css class for the layout
 *                - children: component serves as a container for any children inside it
 */
import { useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { MainErrorFallback } from '@/components/errors/main.jsx';
import { GitRev } from '@/features/misc';
import { MobileNavHeader } from './MobileNavHeader';
import { SideNav } from './SideNav';

import './Layout.css';

/**
 * Renders a layout for pages to use.
 *
 * @param   {object}          props                  Component properties.
 * @param   {string}          props.mobileHeader     Header shown at top of page next to hamburger
 *                                                   menu on low-width mobile screens.
 * @param   {string}          props.contentClassName Classname for styling the content.
 * @param   {React.ReactNode} props.children         Children to render inside the layout.
 * @returns {JSX.Element}                            Layout component.
 */
export function Layout({ contentClassName = '', mobileHeader, children })
{
  const [isNavOpen, setIsNavOpen] = useState(false);

  const closeNavHandler = () => {
    // Don't close right after the hamburger button to open the nav is pressed.
    if (isNavOpen)
    {
      setIsNavOpen(false);
    }
  };

  return (
    <div id="layout">
      <SideNav onClose={closeNavHandler} isOpen={isNavOpen} />
      {/* Initial grid column, since the position fixed sidebar doesn't count as the first grid column. */}
      <div></div>
      <div className={`overflow-hidden ${isNavOpen ? 'blurBg' : ''}`} onClick={closeNavHandler}>
        <MobileNavHeader
          onOpenNav={setIsNavOpen.bind(null, true)}
          header={mobileHeader}
        />
        <GitRev />
        <main className={`content ${contentClassName}`}>
          <ErrorBoundary FallbackComponent={MainErrorFallback}>{children}</ErrorBoundary>
        </main>
      </div>
    </div>
  );
}
