/**
 *  @file       Toast.jsx
 *  @author     Kevin Yu <kevin@distributive.network>
 * 
 *  @date       July 2024
 * 
 *              Toast notification elements that assemble the toast component
 */

import * as React from 'react';
import * as ToastPrimitives from '@radix-ui/react-toast';

import './toast.css';
import { t } from 'i18next';

const ToastProvider = ToastPrimitives.Provider;

const ToastViewport = React.forwardRef(({ className, ...props }, ref) => (
  <ToastPrimitives.Viewport
    ref={ref}
    className="toast-primitive-viewport"
    aria-label={t('notification')}
    {...props}
  />
));
ToastViewport.displayName = ToastPrimitives.Viewport.displayName;

const Toast = React.forwardRef(({ className, variant, ...props }, ref) => {
  return (
    <ToastPrimitives.Root
      ref={ref}
      className="toast-default"
      {...props}
    />
  )});
Toast.displayName = ToastPrimitives.Root.displayName;

const ToastAction = React.forwardRef(({ className, ...props }, ref) => (
  <ToastPrimitives.Action
    ref={ref}
    className="toast-primitive-actions"
    {...props}
  />
));
ToastAction.displayName = ToastPrimitives.Action.displayName;

const ToastClose = React.forwardRef(({ className, ...props }, ref) => (
  <ToastPrimitives.Close
    ref={ref}
    className="toast-primitive-close"
    toast-close=""
    {...props}
  >
    <img src={`${import.meta.env.VITE_ASSETS_PATH}/icons/close.svg`} alt={t('close_toast')} className="close-toast"/>
  </ToastPrimitives.Close>
));
ToastClose.displayName = ToastPrimitives.Close.displayName;

const ToastTitle = React.forwardRef(({ className, ...props }, ref) => (
  <ToastPrimitives.Title
    ref={ref}
    className="toast-primitive-title"
    aria-label={t('copy_to_clipboard')}
    title={t('copy_to_clipboard')}
    {...props}
  />
));
ToastTitle.displayName = ToastPrimitives.Title.displayName;

const ToastDescription = React.forwardRef(({ className, ...props }, ref) => (
  <ToastPrimitives.Description
    ref={ref}
    className="toast-primitive-description"
    {...props}
  />
));
ToastDescription.displayName = ToastPrimitives.Description.displayName;

export {
  ToastProvider,
  ToastViewport,
  Toast,
  ToastTitle,
  ToastDescription,
  ToastClose,
  ToastAction,
}
