/** @file       widgets/button/button.jsx
 *  @author     Alex Huctwith <alex@kingsds.network>
 *
 *  @date       April 2022
 *
 *              A generic button component
 *              Props (all optional):
 *                - click: the onclick action of the button
 *                - colour: true if the button should be coloured, false if white, follows theme colourscheme
 *                - form: relevant form that the button submits
 *                - id: the (html) id
 *                - text: the text/contents of the button
 */

import './Button.css';

/**
 * Renders a Buntton component.
 *
 * @param   {object}      props        Component properties.
 * @param   {boolean}     props.colour Flag for the button's colour.
 * @param   {string}      props.text   Text to display on the button.
 * @param   {string}      props.form   Form to submit when the button is pressed.
 * @param   {object}      props.rest   Other props to pass to the underlying button.
 * @returns {JSX.Element}              Button component.
 */
export function Button({ colour, text, form, className = '', ...rest })
{
  return (
    <button
      className={`${colour ? '' : 'secondary'} ${className}`}
      type={form ? 'submit' : 'button'}
      form={form}
      data-button-primary
      // `novalidate` by default to let react-hook-form handle validation.
      formNoValidate={Boolean(form)}
      {...rest}
    >
      {text}
    </button>
  );
}
