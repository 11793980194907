/**
 * @file    BreadCrumb.jsx
 * 
 *          A generic bread crumb component that allows users to navigate back to previous pages.
 * 
 * @author  Kevin Yu <kevin@distributive.network>
 * @date    June 2024
 */

import { NavLink } from 'react-router-dom';
import './BreadCrumb.css';

/**
 * @param {object} props - Component properties.
 * @param {object[]} props.routes - The routes to render.
 * @return {JSX.Element} The rendered element.
 */
export function BreadCrumb({ routes })
{
  return (
    <div className="bread-crumb">
      {routes.map((route) => (
        <div key={route.href} className="bread-crumb-link">
          <NavLink to={route.href}>
            {route.label}
          </NavLink>
          <span className="bread-crumb-slash">/</span>
        </div>
      ))}
    </div>
  );
}
