/**
 * @file    App.jsx - Renders the Root of the portal app.
 *
 * @author  Alex Huctwith <alex@distributive.network>
 * @date    April 2022
 */

import { useContext, useEffect, useState } from 'react';

import { AccountsDispatchContext } from '@/features/accounts';
import { useUserPrefUpdate } from '@/features/user';
import { AccountOperations } from '@/features/accounts';
import { UserOperations } from '@/features/user';
import { AppRoutes } from '@/routes';

/**
 * Renders the app.
 *
 * @returns {JSX.Element} The entire app component.
 */
function App()
{
  const [isAccountsLoading, setAccountsLoading] = useState(true);
  const { handleRefreshAccounts } = useContext(AccountsDispatchContext);
  const setUserPreferences = useUserPrefUpdate();

  useEffect(() => {
    async function fetchUserPreferences()
    {
      const { showAboutModalOnLogin } = await UserOperations.getUserPreferences();
      setUserPreferences({ showAboutModalOnLogin });
    }

    fetchUserPreferences();
  }, [setUserPreferences]);

  useEffect(() => {
    /**
     * Side effect after initially rendering the app: Fetch the accounts the
     * user has from the server.
     */
    async function fetchAccounts()
    {
      const refreshedAccounts = await AccountOperations.refreshKeystores();
      handleRefreshAccounts(refreshedAccounts);
      setAccountsLoading(false);
    }

    fetchAccounts();
  }, [handleRefreshAccounts]);

  return isAccountsLoading ? (
    <div className="loader-container"></div>
  ) : (
    <AppRoutes />
  );
}

export default App;
