/** 
 * @file       pages/historypage.jsx
 * @author     Alex Huctwith <alex@kingsds.network>
 * @author     Kevin Yu <kevin@distributive.network>
 *
 * @date       May 2024
 *
 *             Portal page for displaying and managing account history
 */

import { BankOperationTabs } from '../components/BankOperationTabs';
import { HistoryFilters } from '../components/HistoryFilters/HistoryFilters';
import { HistoryHeader } from '../components/HistoryHeader/HistoryHeader';
import { Layout } from '@/components/Layout';
import { Transactions } from '../components/Transactions/Transactions';

import './HistoryPage.css';
import { useContext, useEffect } from 'react';
import { HistoryContext } from '../stores/HistoryContext';
import { AccountsContext } from '../stores/AccountsContext';

/**
 *
 * @param   {object}      _props Component properties.
 * @returns {JSX.Element}        Component that renders the transaction/history page.
 */
export function HistoryPage(_props) {
  const { 
    getTransactions,
    setStartDate,
    setEndDate,
    setTransactionType,
    setFilteredTransactions,
    selectedBankAccount,
    setSelectedBankAccount,
  } = useContext(HistoryContext);
  const accounts = useContext(AccountsContext);

  // Essential to fetch transactions when user navigates from accounts to transactions page 
  useEffect(() => {
    // If there's no selected account set the first account <option> as the default
    if (!selectedBankAccount && accounts.length > 0)
      setSelectedBankAccount(accounts[0]);

    getTransactions();

    return () => {
      setStartDate('');
      setEndDate('');
      setTransactionType('');
      setFilteredTransactions([]);
    };
  }, [
    accounts,
    getTransactions,
    selectedBankAccount,
    setEndDate,
    setFilteredTransactions,
    setSelectedBankAccount,
    setStartDate,
    setTransactionType,
  ]);

  return (
    <Layout contentClassName="history-page" mobileHeader="History">
      <div className="history-page-content">
        <HistoryHeader />
        <HistoryFilters />
        <Transactions />
      </div>
      <BankOperationTabs />
    </Layout>
  );
}
