/** @file       operations/accountoperations.js
 *  @author     Alex Huctwith <alex@kingsds.network>
 *
 *  @date       April 2022
 *
 *              Portal client operations related to handling login and session
 */

import PKCE from 'js-pkce';
import i18n from 'i18next';

import { debugging } from '@/lib/debugging';
import { ErrorOperations } from '@/operations/ErrorOperations';

const { oAuth } = window.dcpConfig;
const pkce = new PKCE({
  client_id: oAuth.clientId,
  redirect_uri: new URL('/auth/callback', window.location.origin).href,
  authorization_endpoint: new URL(oAuth.authorizationPath, oAuth.location).href,
  token_endpoint: new URL(oAuth.accessTokenPath, oAuth.location).href,
  requested_scopes: 'login',
});

export class LoginOperations {
  static #loginPromise = false;

  static async load( force = false )
  {
    if (force)
    {
      window.loginPromise = false;
      LoginOperations.#loginPromise = false;
    }

    if (window.loginPromise)
    { // login already underway
      const ret = await window.loginPromise;
      if (!window.portalConnection) // something is wrong, attempt relogin
        return await this.handleLogin();
      return ret;
    }
    else
    { // there is no attempt to login currently happening, attempt one
      window.loginPromise = this.handleLogin();
      return await window.loginPromise;
    }
  }

  /**
   * Logs the user of of their current session.
   *
   * The user is redirected to the signout page on the OAuth server. Eventually, the Oauth server makes a request to the proxy auth service
   * to delete the user's proxy identity from the database, hence the need to send the proxy identity as a search parameter in the redirect.
   */
  static async handleLogout()
  {
    /**
      * Make sure we're fully logged in to be able to send the OAuth token and proxy identity for
      * the request to avoid any potential race conditions.
      *
      * FIXME(bryan-hoang): Arguably a code smell signalling the need to better handle the state of
      * being authenticated in the web app. Having to call this function to guarantee that we're
      * fully authenticated should really be handled in a global (e.g., React context).
      */
    await this.load();
    const proxyKey = (await window.dcp.wallet.getId()).address;
    const signoutUrl = window.dcpConfig.oAuth.location.resolveUrl(`/signout?token=${window.oauth_token}&proxy=${proxyKey}&redirect=${window.location.origin}`);
    window.location.replace(signoutUrl.href);
  }

  /**
   * Login handler function.
   *
   * Sweet summer child, let me tell you how this really works. When the js bundle is loaded for the first time, we call `this.load()` before
   * all other react bootstrapping. Then in most cases, it won't have search params, we use this proxy condition to indicate that we should
   * attempt a login. When we are redirected back to `/auth/callback?<stuff>`, we _will_ have search params and we will perform the
   * authorization token to access token exchange, get the one piece of information we need and throw the access code away. Only then do the
   * logic in /auth/callback take effect and redirect us to what we were intending all along.
   *
   * The special case is when a 'keystore' is provided via search params, then we don't even attempt to login at all. We just assume the an
   * `oAuthToken` will be provided in search param as well, use that to unlock the keystore and proceed.
   *
   * This is some extremely mutation heavy imperative code that only works because we load it before all other react bootstrapping is done.
   */
  static async handleLogin()
  {
    // debounce, so we don't try to redeem the token multiple times concurrently:
    if (LoginOperations.#loginPromise)
      return LoginOperations.#loginPromise;

    // Set the debounce guard; once the login attempt is complete, we'll call doneLogin()
    // to resolve the Promise; the debounce guard will clear on resolve or reject.
    let doneLogin;
    LoginOperations.#loginPromise = new Promise(resolve => {
      doneLogin = () => { 
        resolve();
      };
    }).finally(() => {
      LoginOperations.#loginPromise = false;
    });

    debugging('login') && console.debug('PKCE config:', pkce.config);

    const urlParams = new URLSearchParams(window.location.search);
    const keystore = urlParams.get('keystore');
    const code = urlParams.get('code');

    if (urlParams.size === 0)
    {
      return window.location.replace(pkce.authorizeUrl({
        state: window.location.pathname,
      })); 
    }

    if (keystore) {   // browser-test login
      const idKs = await new window.wallet.IdKeystore(JSON.parse(keystore));
      const oAuthToken = urlParams.get('oAuthToken');

      window.wallet.addId(idKs);
      idKs.unlock(oAuthToken, 86400, true);
      openDCPConnections();

      // Remove auth code from URL.
      window.history.pushState({}, document.title, window.location.pathname);

      return doneLogin(a$getUserInfo());
    }
    else if (!sessionStorage.getItem('pkce_state') || !sessionStorage.getItem('pkce_code_verifier'))
    {
      reattemptAuthorization();
    }
    else
    {
      const url = window.location.href;
      let resp;
      debugging('login') && console.debug(`Exchanging authorization code for access token using ${url}`);
      try
      {
        resp = await pkce.exchangeForAccessToken(url);
      }
      catch(error)
      {
        // The PKCE library doesn't allow us to introspect on what invalid JSON
        // was parsed :(
        if (error instanceof SyntaxError && /JSON\.parse/.test(error.message))
          console.error(`Could not parse response from "${pkce.config.token_endpoint}" during PKCE flow.\n${error}`);
        else
          console.error(error);
        if (ErrorOperations.displayErrorQuestion('Connection to login server failed. Try again?'))
          reattemptAuthorization();
      }

      if (resp.error)
      {
        let errorMessage = `Unable to get access token from "${pkce.config.token_endpoint}" due to reason: "${resp.error}"`
        if (resp.error_description)
          errorMessage += `\nDescription:\n${resp.error_description}`;
        console.error(errorMessage);
        if (ErrorOperations.displayErrorQuestion('Retrieving login failed. Try again?'))
          reattemptAuthorization();
      }

      debugging('login') && console.debug('OAuth server response:', resp);

      await i18n.changeLanguage(resp.locale);
      debugging('login') && console.debug('Current i18n resolved language:', i18n.resolvedLanguage);

      const idKs = await new window.wallet.IdKeystore(resp.keystore);

      window.wallet.addId(idKs);
      idKs.unlock(resp.access_token, 86400, true);

      // set up connections
      debugging('login') && console.debug('Setting up connections to the portal and bank');
      openDCPConnections();

      // touch portal to make sure init happens if using a new account
      await window.portalConnection.request('viewKeystores', {});

      // make oauth token, user email, and user creation date available globally
      window.oauth_token = resp.access_token;
      window.user_email = resp.email;
      window.user_creation_date = resp.creationDate?.slice(0,10);

      // set up expiry event
      setTimeout(ErrorOperations.handleDisconnect, resp.expires_in * 1000);

      debugging('login') && console.debug('Login successful');

      return doneLogin(a$getUserInfo());
    }

    /**
     *  Fetch user's portal info
     *
     *  The backing operation is mostly NYI, but it does return the actual
     *    identity address, which we use in a few places
     *
     *  @return {boolean} Returns true on success
     */
    function a$getUserInfo() {
      return window.portalConnection.request('getUserInfo').then(response => {
        if (response.success)
          sessionStorage.identity = response.payload.id;

        return true;
      })
      .catch(error => {
        debugging('login') && console.error(`Unexpected error fetching user profile...`, error);

        return false;
      });
    }

    /**
     * Navigates the window to the authorization endpoint again to re-attempt
     * the oAuth flow.
     *
     * Necessary because we probably have a state variable that isn't valid for
     * our session.
     */
    function reattemptAuthorization()
    {
      sessionStorage.removeItem('pkce_state');
      sessionStorage.removeItem('pkce_code_verifier');

      window.location.replace(pkce.authorizeUrl({
        state: window.location.pathname,
      }));
    }

    function openDCPConnections()
    {
      function openPortalConnection()
      {
        window.portalConnection = new window.protocol.Connection(window.dcpConfig.portal);
        window.portalConnection.on('end', openPortalConnection);
        debugging('login') && console.debug('New portal connection,', window.portalConnection.debugLabel);
      }
      function openBankConnection()
      {
        window.bankTellerConnection = new window.protocol.Connection(window.dcpConfig.bank.services.bankTeller);
        window.bankTellerConnection.on('end', openBankConnection);
        debugging('login') && console.debug('New bank connection,', window.bankTellerConnection.debugLabel);
      }
      openPortalConnection();
      openBankConnection();
    }

    return false;
  }
}
