/**
 * @file    TransferTab.jsx - Exports a function which renders an interface for transferring credits.
 *
 * @author  Alex Huctwith <alex@kingsds.network>
 * @author  Kirill Kirnichansky <kirill@kingsds.network>
 * @author  Bryan Hoang <bryan@kingsds.network>
 * @date    April 2022, August 2022
 */
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Elements';
import { useForm } from '@/hooks/useForm';
import { debugging } from '@/lib/debugging';
import {
  AccountsContext,
  AccountsDispatchContext,
} from '../../../stores/AccountsContext';
import { AccountSelect } from '../../AccountSelect';
import { AccountOperations } from '../../../api/AccountOperations';
import { CreditsInput } from '../../CreditsInput';


/**
 * Renders a component used for transferring credits between accounts currently owned by the user.
 *
 * @param   {object}      _props Component properties.
 * @returns {JSX.Element}        The transfer form component.
 */
export function TransferTab(_props) {
  const { t } = useTranslation();
  const accounts = useContext(AccountsContext);
  const { handleUpdateAccounts } = useContext(AccountsDispatchContext);

  const {
    register,
    handleSubmit,
    reset,
    setError,
    setValue,
    formState: { errors },
  } = useForm({
    reValidateMode: 'onChange',
  });

  async function transferCredits({ fromAccount, toAccount, amount })
  {
    try
    {
      const refreshedAccounts = await AccountOperations.transferCredits(
        accounts.find(a => a.dbKey === Number(fromAccount)),
        accounts.find(a => a.dbKey === Number(toAccount)),
        accounts,
        amount,
      );

      if (refreshedAccounts)
        handleUpdateAccounts(refreshedAccounts);
      reset();
    }
    catch (error)
    {
      debugging('transfer') && console.error(error.message);
      setError('amount', { type: 'server', message: error.message });
    }
  }

  function handleFromAccountChange()
  {
    const fromInput = document.getElementById('fromAccount');
    const toInput = document.getElementById('toAccount');

    // enabled previously disabled option, disable newly selected from-account
    // options[0] is placeholder
    for (let i = 1; i < toInput.options.length; i++)
    {
      if (toInput.options[i].disabled) toInput.options[i].disabled = false;
      if (toInput.options[i].value === fromInput.value)
      {
        toInput.options[i].disabled = true;

        // Update the `toAccount` if one has already been selected to stop `fromAccount` and
        // `toAccount` from being the same.
        if (toInput.value !== '')
        {
          const availableToAccounts = Array.from(toInput.options).filter((option) => !option.disabled);
          setValue('toAccount', availableToAccounts[0].value);
        }
      }
    }

    // Update amount to be the empty
    setValue('amount', null);
  }

  return (
    <form
      id="transfer-form"
      onSubmit={handleSubmit(transferCredits)}
    >
      <AccountSelect
        label={t('from')}
        placeholder={t('select_account')}
        accounts={accounts}
        error={errors.fromAccount}
        {...register('fromAccount', {
          required: t('must_specify_account_to_transfer_from'),
          onChange: handleFromAccountChange,
        })}
      />
      { errors.fromAccount && <p>{errors.fromAccount?.message}</p> }
      <AccountSelect
        label={t('to')}
        placeholder={t('select_account')}
        accounts={accounts}
        error={errors.fromAccount}
        {...register('toAccount', { 
          required: t('must_specify_account_to_transfer_to')
        })}
      />
      { errors.toAccount && <p>{errors.toAccount?.message}</p> }
      <CreditsInput
        label={t('amount')}
        className={`${errors.amount ? 'invalid' : ''}`}
        {...register('amount', {
          required: t('amount_to_transfer_is_required'),
        })}
      />
      { errors.amount && <p>{errors.amount?.message}</p> }
      <Button colour text={t('transfer')} form="transfer-form" />
    </form>
  );
}
