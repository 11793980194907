/** 
 * @file       HistoryFilters.jsx
 * @author     Kevin Yu <kevin@distributive.network>
 * 
 * @date       May 2024
 *
 *             The filters for the history page
 */

import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from '@/hooks/useForm';

import { HistoryContext } from '../../stores/HistoryContext';

import './HistoryFilters.css';
import { Button } from '@/components/Elements';

const today = new Date();

// Add one day
today.setDate(today.getDate() + 1);

// Convert to ISO string and split to get only the date part 
// Note: we get tomorrows date to deal with the issue of transactions that happened in the "future" with respect to the local timezone
const tomorrow = today.toISOString().split('T')[0];
/**
 * Render the transaction filters
 * 
 * @param   {object}      _props Component properties.
 * @returns {JSX.Element}  The transaction filter options.
 */
export function HistoryFilters(_props) {
  const { 
    setStartDate,
    setEndDate,
    setTransactionType,
    filterTransactions,
  } = useContext(HistoryContext);
  const { t } = useTranslation();
  const { register, handleSubmit } = useForm();

  /**
   * Filter function handler when user clicks search
   * 
   * @param {object | string} param.selectedAccountAddress - The bank account address as an object or a string
   * @param {string} param.startDate - The start date of transactions
   * @param {string} param.endDate - The end date of transactions
   * @param {string} param.transactionType - The type of transaction
   */
  function updateFilteredTransactions({
    startDate,
    endDate,
    transactionType,
  })
  {
    setTransactionType(transactionType);
    setStartDate(startDate)
    setEndDate(endDate)
    filterTransactions();
  }
    
  useEffect(() => {
    filterTransactions();
  }, [filterTransactions]);

  return (
    <form id="transaction-form" className="history-filters" onSubmit={handleSubmit(updateFilteredTransactions)}>
      <div className="history-filters-dropdowns">
        {/* Account */}

        {/* Date Range */}
        <div className="history-filters-date-range">
          <label>{t('date_range')}</label>
          <div className="history-filters-dates-container">
            {/* Start Date */}
            <input
              type="date"
              id="history-filters-date-range"
              {...register('startDate')}
              max={tomorrow}
            />
            <span>{t('to').toUpperCase()}</span>
            {/* End Date */}
            <input
              type="date"
              id="history-filters-date-range"
              {...register('endDate')}
              max={tomorrow}
            />
          </div>
        </div>

        {/* Transaction Types */}
        <div className="history-filters-transaction-types">
          <label htmlFor="transaction-type">{t('transaction_type')}</label>
          <select
            id="transaction-type"
            onChange={(e) => setTransactionType(e.target.value)}
            defaultValue="All"
            {...register('transactionType')}
          >
            <option value="All">{t('all')}</option>
            <option value="Buy">{t('buy')}</option>
            <option value="Sell">{t('sell')}</option>
          </select>
        </div>
      </div>
      
      {/* Search */}
      <div className="history-filters-query-btns">
        <Button colour className="history-filters-search" text={t('search')} form="transaction-form" />
      </div>
    </form>
  );
}
