/**
 * @file    CopyToClipboard.jsx
 *          exports a component for copying account numbers to user clipboard
 * @author  Arnab Sagar <arnab@kingsds.network>
 * @author  Maneesh Wijewardhana <maneesh@kingsds.network>
 * @date    August 2022
 */

import { forwardRef, useImperativeHandle } from 'react';

import { t } from 'i18next';
import { useToast } from './Toast/useToast';

const { VITE_ASSETS_PATH } = import.meta.env;

/**
 * Renders a button that copies given text to the user's clipboard.
 *
 * @param   {object}      props          Component properties.
 * @param   {string}      props.copyText The text to copy.
 * @returns {JSX.Element}                The rendered component.
 */
export const CopyToClipboard = forwardRef(function CopyToClipboard(
  { copyText, className = '', toastMessage = t('copy_to_clipboard') },
  ref,
)
{
  const { toast } = useToast();
  async function handleClickToCopy(e)
  {
    e.stopPropagation();
    await navigator.clipboard.writeText(copyText);
    toast({
      title: toastMessage,
    });
  }

  useImperativeHandle(ref, () => {
    return {
      handleClickToCopy
    };
  })

  return (
    <button
      className={`copy-to-clipboard ${className}`}
      title={t('copy_to_clipboard')}
      type="button"
      onClick={handleClickToCopy}
    >
      <img src={`${VITE_ASSETS_PATH}/icons/copy.svg`} alt={t('copy_to_clipboard')} />
    </button>
  );
});
