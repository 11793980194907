/**
 * @file    ChangePassword.jsx - Exports a function that renders a section for changing the
 *          user's password.
 * @author  Danny Akbarzadeh <danny@kingsds.network>
 *          Robert Mirandola <robert@distributive.network>
 * @date    July 2022
 *          April 2023
 */

import { useTranslation } from 'react-i18next'
import { useState } from 'react';

import { ErrorOperations } from '@/operations/ErrorOperations';
import { UserOperations } from '@/features/user';
import { LoginOperations } from '@/features/auth';
import { useForm } from '@/hooks/useForm';
import { Button } from '@/components/Elements';
import { debugging } from '@/lib/debugging';
import { PasswordInput } from '@/components/Form';

import './ChangePassword.css';

/**
 * Renders an element with a button to send a reset password email.
 * @param   {object}      _props Component properties.
 * @returns {JSX.Element}        The component to render
 */
export function ChangePassword(_props)
{
  const {
    register,
    handleSubmit,
    formState: { isDirty, errors },
    watch,
    reset,
    getValues
  } = useForm({
    defaultValues: {
      current_password: '',
      new_password: '',
      confirm_new_password: '',
    }
  });
  const { t } = useTranslation();
  const [ isChanged, setChanged ] = useState(false);
  const resetPasswordButtonText = t('reset_password');
  

  /**
   * Handles saving password change. Checks occur involving current password validation and 
   * that the confirmation password matches the new password
   *
   * @param {SubmitEvent} event
   */
  async function savePressed(_event)
  {
    setChanged(false);
    const saveButton = document.getElementById('password-save');
    saveButton.innerHTML = `<span className="animated-ellipsis">
                              <span>.</span>
                              <span>.</span>
                              <span>.</span>
                            </span>`;
    
    const reqBody = { 
      email: window.user_email,
      new_password: getValues('new_password'),
    };
    
    const apiUrl = window.dcpConfig.oAuth.location.resolve('/users/password');
    
    /* Send PUT request to OAuth server to change password */
    let resp;
    try {
      resp = await fetch(apiUrl, {
        method: 'PUT',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${window.oauth_token}`,
        },
        body: JSON.stringify(reqBody),
      });
    }
    catch(error)
    {
      ErrorOperations.displayError(t('could_not_reset_password'));
      debugging('accounts') && console.error('ChangePassword', error);
      saveButton.textContent = resetPasswordButtonText;
      return;
    }
    
    const success = await UserOperations.checkPassword(watch('new_password'));
    
    // If result was a 401, then we need to log in again
    if (resp.status === 401)
      return LoginOperations.load(true);

    if (!resp.ok || !success)
    {
      ErrorOperations.displayError(t('could_not_reset_password'));
      debugging('accounts') && console.warn('ChangePassword: Password change update -', resp.ok, resp.status, resp.statusText);
      saveButton.textContent = resetPasswordButtonText;
    }
    else
    {
      setChanged(true); /* Show success message */
      saveButton.textContent = '✓';
      setTimeout(() => {
        saveButton.textContent = resetPasswordButtonText;
      }, 2000);
    }
    
    reset();
  }
  
  function validateCurrentPassword() 
  {
    setChanged(false);
    return UserOperations.checkPassword(watch('current_password'));
  }
  
  function validatePasswordsMatch()
  {
    setChanged(false);
    return watch('new_password') === watch('confirm_new_password');
  }

  return (
    <div className="change-password userContent">
      <div className="label">
        <h2 id="resetPasswordHeader">{t('change_password')}</h2>
      </div>
      <form id="change-password" onSubmit={handleSubmit(savePressed)}>
        {errors.current_password && (
          <p className="errorMessage">{t('current_password_incorrect')}</p>
        )}
        {isChanged && ( 
          <div className="success-message">{t('password_reset_successful')}</div> 
        )}
        <div className="form-row">
          <PasswordInput
            name="change-password[current_password]"
            label={t('current_password')}
            {...register('current_password', { validate: validateCurrentPassword })}
          />
        </div>
        <div className="form-row">
          <PasswordInput
            name="change-password[new_password]"
            label={t('new_password')}
            {...register('new_password', { 
              required: true,
              minLength: 6,
            })}
          />
          <PasswordInput
            name="change-password[confirm_password]"
            label={t('confirm_new_password')}
            {...register('confirm_new_password', {
              required: true, 
              validate: validatePasswordsMatch,
            })}
          />
        </div>
        {(errors.new_password && errors.new_password.type === 'required') && (
          <p className="errorMessage">{t('new_password_is_required')}</p>
        )}
        {(errors.new_password && errors.new_password.type === 'minLength') && (
          <p className="errorMessage">{t('password_too_short')}</p>
        )}
        {errors.confirm_new_password && (
          <p className="errorMessage">{t('password_must_match')}</p>
        )}
        <div className="form-row">
          <Button
            colour={true}
            text={resetPasswordButtonText}
            id="password-save"
            form="change-password"
            disabled={!isDirty}
          />
        </div>
      </form>
    </div>
  );
}
