/**
 *  @file       UserOperations.js
 *  @author     Alex Huctwith <alex@kingsds.network>
 *
 *  @date       April 2022
 *
 *              Portal client operations related to user/identity
 */

import { ErrorOperations } from '@/operations/ErrorOperations';
import { LoginOperations } from '@/features/auth';

export class UserOperations
{
  static async updateUserPreferences(updatedPreferences)
  {
    if (!window.portalConnection)
    {
      await LoginOperations.load();
    }

    const { success, payload } = await window.portalConnection.request(
      'updateUserPreferences',
      updatedPreferences,
    );
    if (!success)
    {
      ErrorOperations.displayError(payload.message);
    }
  }

  static async getUserPreferences()
  {
    if (!window.portalConnection)
    {
      await LoginOperations.load();
    }

    const { success, payload } = await window.portalConnection.request('getUserPreferences');
    if (!success)
    {
      ErrorOperations.displayError(payload.message);
    }

    return payload;
  }

  static async getAddress()
  {
    if (!window.portalConnection)
    {
      await LoginOperations.load();
    }

    return sessionStorage.identity;
  }

  static async getIdentities()
  {
    if (!window.portalConnection)
    {
      await LoginOperations.load();
    }
    const { success, payload } = await window.portalConnection.request(
      'getIdentities',
    );
    if (!success)
    {
      ErrorOperations.displayError(payload.message);
    }
    return payload.identities;
  }

  static async createIdentity(label)
  {
    if (!window.portalConnection)
    {
      await LoginOperations.load();
    }
    else
    {
      const response = await window.portalConnection.request('provisionIdentity', {
        label,
      });
      if (!response.success)
      {
        ErrorOperations.displayError(response.payload.message);
      }
      else
      {
        return response.payload.identity;
      }
    }
  }

  static async deleteIdentity(identity)
  {
    if (!window.portalConnection)
    {
      await LoginOperations.load();
    }
    else
    {
      const address = identity.address;
      const response = await window.portalConnection.request('revokeIdentity', {
        address,
      });
      if (!response.success)
      {
        ErrorOperations.displayError(response.payload.message);
      }
    }
  }

  static async downloadIdentity(identity, passphrase)
  {
    const tempKeystore = await new window.wallet.Keystore(identity.keystore);
    const lockedKeystore = await new window.wallet.Keystore(
      await tempKeystore.getPrivateKey(),
      passphrase,
    );
    const ksJSON = lockedKeystore.toJSON();
    ksJSON.label = identity.label;
    window.ksJson = ksJSON;
    const payload =
      'data:text/json;charset=utf-8,' +
      encodeURIComponent(JSON.stringify(ksJSON));
    const downloadLink = document.getElementById('downloadLink');
    downloadLink.setAttribute('href', payload);
    downloadLink.setAttribute('download', identity.label + '.keystore');
    downloadLink.click();
  }
  
  static async checkPassword(password)
  {
    const apiUrl = window.dcpConfig.oAuth.location.resolve(
      '/api/v1/users/checkPassword',
    );
    
    const res = await fetch(apiUrl, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${window.oauth_token}`,
      },
      body: JSON.stringify({ password }),
    })
    
    if (!res.ok)
    {
      ErrorOperations.displayError('Could not validate password.');
      return false;
    }
    
    const data = await res.json();
    return data.valid;
  }
}
