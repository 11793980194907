/**
 * @file       AccountsPage.jsx
 *             Page displaying Bank Accounts to users, linked to by "Accounts" option in sidebar
 *
 * @author     Alex Huctwith alex@kingsds.network
 *             Danny Akbarzadeh danny@kingsds.network
 *             Kevin Yu kevin@distributive.network
 * @date       June 2022
 */
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Layout } from '@/components/Layout';
import { Button } from '@/components/Elements';
import { BankOperationTabs } from '../components/BankOperationTabs';
import { CreateAccountModal, UnlockKeystoreModal, UploadKeystoreModal } from '../components/Modals';
import { Accounts } from '../components/Accounts';

/**
 * Function imported by the main React App that gets called
 * to draw the "Bank Accounts" component (content pane);
 *
 * @param   {object}      _props Component properties.
 * @returns {JSX.Element}        Component that renders the accounts page.
 */
export function AccountsPage(_props)
{
  const { t } = useTranslation();
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);

  function openCreateModal()
  {
    setIsCreateModalOpen(true);
  }

  function closeCreateModal()
  {
    setIsCreateModalOpen(false);
  }

  function openUploadModal()
  {
    setIsUploadModalOpen(true);
  }

  function closeUploadModal()
  {
    setIsUploadModalOpen(false);
  }

  return (
    <Layout contentClassName="accountsPage" mobileHeader={t('accounts')}>
      {/* 1st grid column */}
      <div className='accounts-table-section'>
        <div className='accounts-table-container'>
          <Accounts />
        </div>
        <div className="buttonsFlex accounts-btns">
          <Button
            onClick={openCreateModal}
            id="openCreateModalButton"
            text={t('create_new_account')}
          />
          <Button
            colour
            onClick={openUploadModal}
            id="openUploadModalButton"
            text={t('upload_keystore')}
            className="xs-hidden"
          />
        </div>
      </div>
      {/* 2nd grid column */}
      <BankOperationTabs />
      <CreateAccountModal
        isOpen={isCreateModalOpen}
        closeModal={closeCreateModal}
      />
      <UploadKeystoreModal
        isOpen={isUploadModalOpen}
        closeModal={closeUploadModal}
      />
      <UnlockKeystoreModal />
    </Layout>
  );
}
