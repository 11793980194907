/**
 * @file    BuyTab.jsx
 * @author  Alex Huctwith <alex@kingsds.network>
 * @author  Bryan Hoang <bryan@distributive.network>
 * @author  Robert Mirandola <robert@distributive.network>
 * @date    April 2022, September 2022
 */

import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { useForm } from '@/hooks/useForm';
import { Button } from '@/components/Elements';
import {
  AccountsContext,
  AccountsDispatchContext,
} from '../../../stores/AccountsContext';
import { AccountSelect } from '../../AccountSelect';
import { AccountOperations } from '../../../api/AccountOperations';
import { CreditsInput } from '../../CreditsInput';


const { location, purchasePath, clientId } = window.dcpConfig.oAuth;
const path = `${location.resolve(purchasePath)}?uid=${clientId}`;

/**
 * Renders a component which displays an interface for buying credits.
 *
 * @param   {object}      _props Component properties.
 * @returns {JSX.Element}        The rendered component.
 */
export function BuyTab(_props)
{
  const { t } = useTranslation();
  const accounts = useContext(AccountsContext);
  const { handleUpdateAccounts } = useContext(AccountsDispatchContext);
  const { register, handleSubmit, formState: { errors } } = useForm({
    reValidateMode: 'onChange',
  }); 

  function buyPressed({ accountSelected, amount })
  {
    const toAccount = accounts.find((account) => {
      return account.dbKey === Number(accountSelected);
    });
    
    window.open(
      `${path}&toAccount=${toAccount.dbKey}&amount=${amount}`,
      'Buy Credits',
      'height=800,width=600',
    );

    window.addEventListener('message', async (event) => {
      if (event.origin !== location.origin)
        return;

      switch (event.data)
      {
        case 'success':
        {
          const updatedAccounts = await AccountOperations.refreshBalance(accounts);

          if (updatedAccounts)
            handleUpdateAccounts(updatedAccounts);
          break;
        }
        case 'failure':
          break;
        default:
          break;
      }
    }, false);
  }

  return (
    <form id="buy-form" onSubmit={handleSubmit(buyPressed)}>
      <AccountSelect 
        label={t('to')}
        placeholder={t('select_account')}
        accounts={accounts}
        error={errors.accountSelected}
        {...register('accountSelected', {
          required: t('must_specify_account_to_deposit'),
        })} />
      {errors.accountSelected && <p>{errors.accountSelected?.message}</p>}
      <CreditsInput 
        label={t('amount')} 
        className={`${errors.amount ? 'invalid' : ''}`}
        {...register('amount', {
          required: t('amount_to_buy_is_required'),
        })}/>
      {errors.amount && <p>{errors.amount?.message}</p>}
      <Button colour text={t('buy')} form="buy-form" />
    </form>
  );
}
